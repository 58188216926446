import { CornersOut } from "@phosphor-icons/react";
import DocPreview from "components/DocPreview";
import Overlay from "components/Overlay";
import { useState } from "react";
import { Button, useEventListener } from "x-wings";

export type TLargePreviewPropsType = {
	className?: string;
	s3Path: string;
};

const LargePreview: React.FC<TLargePreviewPropsType> = ({ className, s3Path }) => {
	const [showPreview, setShowPreview] = useState(false);

	const handleShowPreview = () => setShowPreview(true);
	const handleHidePreview = () => setShowPreview(false);

	useEventListener("keydown", (e) => {
		if (!showPreview) return;
		if (e.key === "Escape") setShowPreview(false);
	});

	return (
		<div className={className}>
			<div className="relative overflow-hidden">
				<Button
					type="default"
					icon={<CornersOut weight="bold" />}
					onClick={handleShowPreview}
					className="absolute top-2 right-2 z-30 opacity-0 [div:hover>&]:opacity-100 transition-opacity"></Button>
				<DocPreview isThumbnail={!showPreview} disableControls={!showPreview} s3Path={s3Path} />
			</div>
			<Overlay open={showPreview} onClose={handleHidePreview}>
				<DocPreview s3Path={s3Path} />
			</Overlay>
		</div>
	);
};

export default LargePreview;

