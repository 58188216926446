import { apiInstance } from "api/instance";

export const getChangeLogs = async () => (await apiInstance.get<ApiResponse<TRelease[]>>("releases/")).data.data;

export const getLatestChangelogVersion = () => apiInstance.get<ApiResponse<TRelease>>("releases/latest-release");

export const createRelease = async (payload: { demoLink?: string; version: "Major" | "Patch" | "Minor" }) => {
	return (
		await apiInstance.post<
			ApiResponse<{
				items: [];
				demo_link?: string;
				version: string;
				_id: string;
				created_at: string;
				updated_at: string;
			}>
		>("releases/", payload)
	).data.data;
};

export const addItemToRelease = async (payload: {
	changes: string[];
	note?: string;
	title: string;
	releaseId: string;
	files: File[];
}) => {
	const formData = new FormData();
	formData.append("changes", JSON.stringify(payload.changes));
	formData.append("note", payload.note || "");
	formData.append("title", payload.title);
	formData.append("releaseId", payload.releaseId);

	payload.files.forEach((file) => {
		formData.append("file", file); // Same key for all files
	});

	return await apiInstance.put<ApiResponse>("releases/add-item-to-release", formData, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};
