import { apiInstance } from "api/instance";

export const searchLogisticOrderTasks = async (payload: TPayloadSearchOnOrderLevelLogisticTasks) => {
	return (
		await apiInstance.post<ApiResponse<{ data: TLogisticOrderTask[]; count: number }>>(
			`/logistics/search-on-order-level`,
			payload
		)
	).data.data;
};
