import clsx from "clsx";
import React from "react";

type IProps = {
	keys: string | string[] | React.ReactElement | React.ReactElement[];
	className?: string;
};

const KeyboardButton: React.FC<IProps> = ({ keys, className = "" }) => {
	const _keys = typeof keys === "string" ? (keys as string).split(",") : keys;
	return (
		<div className={clsx("justify-start items-start gap-1 inline-flex", className)}>
			{Array.isArray(_keys) &&
				_keys.map((_, index) => (
					<div
						key={index}
						className="h-5 px-1 py-0.5 bg-gray-400/15 rounded-md border border-gray-200 flex-col justify-center items-center gap-2 inline-flex">
						<div className="text-center text-current uppercase text-[11px] font-normal font-['Inter'] leading-tight">
							{_}
						</div>
					</div>
				))}

			{React.isValidElement(_keys) && (
				<div className="h-5 px-1 py-0.5 bg-gray-400/15 rounded-md border border-gray-200 flex-col justify-center items-center gap-2 inline-flex">
					<div className="text-center text-current uppercase text-[11px] font-normal font-['Inter'] leading-tight">
						{_keys}
					</div>
				</div>
			)}
		</div>
	);
};

export default KeyboardButton;

