import { apiInstance } from "api/instance";

const getExt = (filename: string): string => {
	return filename.split(".").pop()?.toLowerCase() ?? "";
};
export const getFileURLfromFilename = async (s3Path: string, useCache = true) => {
	const encodedFilename = encodeURIComponent(s3Path);

	const { data } = await apiInstance.get<ApiResponse<string[]>>(`documents/signed-url?filename=${encodedFilename}`);

	const signedURLs: TSignedURL[] = data.data.map((url) => {
		const urlObj = new URL(url);
		const filename = urlObj.pathname.split("/").pop();
		const extension = getExt(filename as string);
		if (!filename) return { url, filename: "", extension: "" };
		return { url, filename, extension };
	});

	return signedURLs;
};

export const getAssetsList = (assetsId: string[] | string) =>
	apiInstance.get<ApiResponse>("assets/assets_list", { params: { assets_id: assetsId } });

