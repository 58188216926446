import { useCallback, useEffect, useState } from "react";
import { getAppConfig as getAppConfigApi, putAppConfig as putAppConfigApi } from "./Api";
import { AppConfigSchema } from "./schema";

const getInitialAppConfig = (): TAppConfig => {
	const init = {
		metadata: {},
		updated_at: "",
		version_key: "initial_version_key[useAppConfig.tsx]"
	};

	const appConfigLocalResult = AppConfigSchema.safeParse(
		JSON.parse(localStorage.getItem("app-config") ?? JSON.stringify(init))
	);

	if (appConfigLocalResult.success) {
		return appConfigLocalResult.data;
	}

	return init;
};

const useAppConfig = () => {
	const [appConfig, setAppConfig] = useState<TAppConfig>(getInitialAppConfig());

	useEffect(() => {
		localStorage.setItem("app-config", JSON.stringify(appConfig));
	}, [appConfig]);

	const getAppConfig = useCallback(
		async (version: string) => {
			if (version === appConfig.version_key) return appConfig;

			try {
				const { data } = await getAppConfigApi();
				setAppConfig({
					metadata: data.data.metadata,
					updated_at: data.data.updated_at,
					version_key: data.data.version_key
				});
				return data.data;
			} catch (error) {
				console.log("🚀 ~ getAppConfig ~ error:", error);
			}
		},
		[appConfig]
	);

	const patchAppConfig = useCallback(
		async (data: Record<string, any>) => {
			const appConfigData = {
				...appConfig.metadata,
				...data
			};

			try {
				const { data } = await putAppConfigApi(appConfigData);
				setAppConfig({
					metadata: data.data.metadata,
					updated_at: data.data.updated_at,
					version_key: data.data.version_key
				});
				return data.data;
			} catch (error) {
				console.log("🚀 ~ patchAppConfig ~ error:", error);
			}
		},
		[appConfig]
	);

	return { appConfig, getAppConfig, patchAppConfig };
};

export default useAppConfig;

