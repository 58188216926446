import { AuthWrapper } from "pages/Auth/Context";
import { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import WithRouter from "routes/WithRouter";
import { ThemeProvider, useEventListener } from "x-wings";
import "./styles/index.scss";
import sentry from "./utils/sentry";

function App({ Component, pageProps }: any) {
	useEffect(() => {
		const handleHashChange = () => {
			const url = new URL(window.location.href);
			url.search = "";
			window.history.replaceState(null, "", url.toString());
		};

		window.onhashchange = handleHashChange;

		return () => {
			window.onhashchange = null;
		};
	}, []);

	useEventListener("keydown", (e: KeyboardEvent) => {
		if (e.key === "F8") {
			sentry.showFeedBackModal();
		}
	});

	return (
		<HashRouter>
			<>
				<ThemeProvider>
					<AuthWrapper>
						<Component {...pageProps} />
					</AuthWrapper>
				</ThemeProvider>
			</>
		</HashRouter>
	);
}

export default WithRouter(App);
