import { MagnifyingGlass, Spinner } from "@phosphor-icons/react";
import KeyboardButton from "components/Commons/KeyboardButtons";
import Overlay from "components/Overlay";
import { Suspense, lazy } from "react";
import { useBoolean, useEventListener } from "usehooks-ts";

const CommandPalette = lazy(() => import("./CommandPalette"));

const Search = ({ isMinimized }: { isMinimized: boolean }) => {
	const { value: isOpen, setTrue: setOpenTrue, setFalse: setOpenFalse } = useBoolean();

	useEventListener("keydown", (e) => {
		if (["INPUT", "TEXTAREA", "SELECT"].includes((e.target as HTMLElement).tagName)) return;
		if (!isOpen && (e.key === "S" || e.key === "s") && e.shiftKey) {
			e.preventDefault();
			setOpenTrue();
		}
	});

	return (
		<>
			{!isMinimized && (
				<div
					className="h-9 relative w-full flex items-center gap-2 px-4 py-2 mt-4 rounded-lg text-gray-400 bg-white focus:outline-none focus:bg-white focus:shadow-outline
					ring-1 ring-inset ring-gray-200 ring-offset-[1px] hover:ring-2 hover:ring-offset-0 animate-fade-in
					"
					role="button"
					tabIndex={0}
					onClick={setOpenTrue}>
					<MagnifyingGlass size={15} />
					Search
					<KeyboardButton
						keys={["⇧", "S"]}
						className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500"
					/>
				</div>
			)}

			{isMinimized && (
				<>
					<div
						role="button"
						tabIndex={0}
						onClick={setOpenTrue}
						className=" h-9 w-9 -mx-1 flex items-center justify-center ring-1 ring-inset text-gray-500 bg-white ring-gray-200 ring-offset-[1px] hover:ring-2 hover:ring-offset-0 rounded-lg">
						<MagnifyingGlass size={15} />
					</div>
				</>
			)}

			<Overlay open={isOpen} onClose={setOpenFalse}>
				<Suspense
					fallback={
						<div className="grid place-items-center">
							<Spinner className="animate-spin text-primary-600" size={24} />
						</div>
					}>
					<CommandPalette onCloseSearch={setOpenFalse} />
				</Suspense>
			</Overlay>
		</>
	);
};

export default Search;
