import { getFileURLfromFilename } from "api/assets";
import { useEffect, useState } from "react";

const useDocumentFromS3 = (s3Path?: string) => {
	const [isLoading, setIsLoading] = useState(false);
	const [src, setSrc] = useState<string>();
	const [isPdf, setIsPdf] = useState(false);
	useEffect(() => {
		const fetchAsset = async () => {
			if (!s3Path) return;
			setIsLoading(true);
			setSrc(undefined);
			try {
				const signedUrls = await getFileURLfromFilename(s3Path);
				setIsPdf(signedUrls[0].extension.toLowerCase().includes("pdf"));
				setSrc(signedUrls[0].url);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchAsset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [s3Path]);

	return { isLoading, src, isPdf };
};

export default useDocumentFromS3;

