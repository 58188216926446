import { ListDashes } from "@phosphor-icons/react";
import PageHeader from "components/Commons/PageHeader";
import { useRef } from "react";
import OrderTasksTable from "./Components/OrderTasksTable";

const LogisticOrders = () => {
	const headerActionsRef = useRef(null);

	return (
		<>
			<PageHeader>Logistic</PageHeader>

			<main className="~px-4/8 mt-5">
				<div className="w-full justify-between items-center inline-flex mb-6">
					<div className="justify-start items-center gap-2 flex">
						<ListDashes className="w-5 h-5 relative text-gray-500" />
						<div className="text-black text-base font-semibold font-['Inter'] leading-normal flex items-center gap-3">
							Orders
						</div>
					</div>
					<div className="justify-start items-start gap-2 flex relative" ref={headerActionsRef} />
				</div>

				<section>
					<OrderTasksTable actionContainerRef={headerActionsRef} />
				</section>
			</main>
		</>
	);
};

export default LogisticOrders;
