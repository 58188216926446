import dayjs from "dayjs";
import { useAuthContext } from "pages/Auth/Context";
import { useEffect, useReducer } from "react";
import { useSearchParams } from "react-router-dom";

type TLogisticTasksRootState = TPayloadSearchOnOrderLevelLogisticTasks & {
	// Define the root state type here
	currentTask: TLogisticTask | null;
	filters: string[];
};

type TLogisticTasksAction =
	| {
			type: "SEARCH_CHANGE";
			payload: TLogisticTasksRootState["searchText"];
	  }
	| {
			type: "PAGINATION_CHANGE";
			payload: number;
	  }
	| {
			type: "FILTER_CHANGE";
			payload: string[];
	  };

export const TASKS_FILTERS = [
	{
		key: "unassigned",
		label: "Unassigned",
		filterKey: "status",
		filterValue: ["UNASSIGNED"]
	},
	{ key: "assigned", label: "Assigned", filterKey: "status", filterValue: ["ASSIGNED"] },
	{
		key: "task-today",
		label: "Today's Tasks",
		filters: {
			start_date: dayjs().startOf("day").toISOString(),
			end_date: dayjs().endOf("day").toISOString()
		}
	},
	{
		key: "task-tomorrow",
		label: "Tomorrow's Tasks",
		filters: {
			start_date: dayjs().add(1, "day").startOf("day").toISOString(),
			end_date: dayjs().add(1, "day").endOf("day").toISOString()
		}
	},
	{
		key: "overdue",
		label: "Overdue",
		filters: {
			end_date: dayjs().startOf("day").toISOString(),
			status: ["ASSIGNED"]
		}
	},
	{ key: "completed", label: "Completed", filterKey: "status", filterValue: ["COMPLETED"] }
];

const initialState: TLogisticTasksRootState = {
	// Define initial state values here
	searchText: undefined,
	limit: 10,
	skip: 0,
	sort: ["created_at#!#-1"],
	assignees: [],
	currentTask: null,
	filters: []
};

const reducer = (state = initialState, action: TLogisticTasksAction): TLogisticTasksRootState => {
	switch (action.type) {
		case "SEARCH_CHANGE":
			return {
				...state,
				searchText: action.payload,
				skip: 0
			};
		case "PAGINATION_CHANGE":
			return {
				...state,
				skip: action.payload
			};

		case "FILTER_CHANGE":
			const current = TASKS_FILTERS.find((filter) => filter.key === action.payload[0]);
			if (current?.filterKey === "status") {
				return {
					...state,
					status: current?.filterValue as TLogisticTask["status"][],
					start_date: undefined,
					end_date: undefined,
					filters: action.payload,
					skip: 0
				};
			}
			if (current?.filters) {
				return {
					...state,
					start_date: current.filters.start_date,
					end_date: current.filters.end_date,
					status: current.filters.status as TLogisticTask["status"][],
					filters: action.payload,
					skip: 0
				};
			}

			return {
				...state,
				status: undefined,
				start_date: undefined,
				end_date: undefined,
				filters: action.payload,
				skip: 0
			};

		default:
			return state;
	}
};

const useLogisticTasksReducer = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { user } = useAuthContext();

	const _ = useReducer(reducer, {
		...initialState,
		searchText: searchParams.get("q") || undefined,
		assignees: user?.type === "LOGISTICS_PARTNER" ? [user._id] : undefined,
		filters: ["task-today"],
		start_date: dayjs().startOf("day").toISOString(),
		end_date: dayjs().endOf("day").toISOString()
	});

	const state = _[0];

	useEffect(() => {
		const { searchText } = state;

		setSearchParams((_) => {
			searchText && _.set("q", searchText);
			!searchText && _.delete("q");
			return _;
		});

		return () => {};
	}, [setSearchParams, state]);

	return _;
};

export default useLogisticTasksReducer;
