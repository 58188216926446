import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useQuery } from "@tanstack/react-query";
import PageHeader from "components/Commons/PageHeader";

import { CircleNotch } from "@phosphor-icons/react";
import clsx from "clsx";
import { useAuthContext } from "pages/Auth/Context";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { fetchToken, getEmbedSuperSetDashboards } from "./Api";

const Reports: React.FC = () => {
	const { user } = useAuthContext();
	const [selectedDashboardId, setSelectedDashboardId] = useState<string>("");
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: dashboards, isFetching } = useQuery({
		queryKey: ["getEmbedSuperSetDashboards"],
		queryFn: getEmbedSuperSetDashboards,
		initialData: [],
		refetchOnWindowFocus: false
	});

	useEffect(() => {
		if (dashboards.length === 0) return;
		const searchQueryDashBoardId = searchParams.get("dashboard");
		if (searchQueryDashBoardId) {
			const dashboard = dashboards.find((d) => d.embedDashboardId === searchQueryDashBoardId);
			if (dashboard) {
				setSelectedDashboardId(dashboard.embedDashboardId);
				return;
			}
		}
		setSelectedDashboardId(dashboards[0].embedDashboardId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboards]);

	useEffect(() => {
		setSearchParams({ dashboard: selectedDashboardId }, { replace: true });
	}, [selectedDashboardId, setSearchParams]);

	useEffect(() => {
		if (!selectedDashboardId) return;
		if (dashboards.length === 0) return;
		embedDashboard({
			id: selectedDashboardId,
			supersetDomain: "https://analytics.stampmyvisa.com",
			mountPoint: document.getElementById("my-superset-container") as HTMLElement,
			fetchGuestToken: () =>
				fetchToken(selectedDashboardId, user?.first_name || "", user?.last_name || "", user?._id || ""),
			dashboardUiConfig: {
				filters: {
					expanded: false
				}
			}
		}).catch((error) => {
			setSelectedDashboardId(dashboards[0].embedDashboardId);
			console.error("Failed to embed dashboard", error);
		});
	}, [dashboards, selectedDashboardId, user]);

	if (isFetching) return <CircleNotch size={32} className="text-indigo-600 animate-spin" />;

	if (dashboards.length === 0) return <div className="grid h-full place-items-center">No dashboards found</div>;

	const handleDashboardChange = (dashboardId: string) => {
		Mixpanel.track(MIXPANEL_EVENTS.DASHBOARD_SELECTED, {
			dashboard: dashboards.find((d) => d.embedDashboardId === dashboardId)?.dashboardName
		});
		setSelectedDashboardId(dashboardId);
	};

	return (
		<div className="h-full grid grid-rows-[min-content_min-content_auto]">
			<PageHeader>Reports</PageHeader>
			<div className="flex items-center gap-3 px-4 py-3 flex-wrap">
				{dashboards.length > 0 && (
					<>
						<p className="text-sm font-medium">Dashboards</p>
						<span className="w-px bg-gray-200 h-5 rounded-px" />
					</>
				)}
				{dashboards.map((dashboard) => (
					<button
						key={dashboard.embedDashboardId}
						className={clsx(
							"my-px flex items-center transition-colors border border-gray-400 rounded-full px-2.5 h-[22px]",
							selectedDashboardId === dashboard.embedDashboardId
								? "bg-primary-50 border-primary-400 border-solid"
								: "border-dashed"
						)}
						onClick={() => handleDashboardChange(dashboard.embedDashboardId)}>
						<span
							className={clsx(
								"text-gray-600 text-xs font-medium font-['Inter'] leading-tight",
								selectedDashboardId === dashboard.embedDashboardId && "text-primary-600"
							)}>
							{dashboard.dashboardName}
						</span>
					</button>
				))}
			</div>
			{!selectedDashboardId && <p className="mt-10 text-center">Select a dashboard to view reports</p>}
			<div
				className="superset-container place-self-stretch relative [&_iframe]:w-full [&_iframe]:h-full [&_iframe]:border-none"
				id="my-superset-container"></div>
		</div>
	);
};

export default Reports;

