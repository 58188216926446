import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import type { UploadFile } from "antd/es/upload/interface";
import toast from "components/Commons/Toaster";
import { addItemToRelease, createRelease, getLatestChangelogVersion } from "pages/Changelog/Api";
import React, { useState } from "react";

type TReleasePayload = {
	demoLink?: string;
	version: "Major" | "Patch" | "Minor";
};

const ReleaseForm: React.FC<{
	setReleaseDetails: React.Dispatch<
		React.SetStateAction<{
			_id: string;
			version: string;
		} | null>
	>;
}> = ({ setReleaseDetails }) => {
	const [form] = Form.useForm<TReleasePayload>();

	const onFinish = async (values: TReleasePayload) => {
		try {
			const release = await createRelease(values);
			toast.success("Release created successfully!");
			form.resetFields();
			setReleaseDetails(release);
		} finally {
		}
	};

	const onUseLatestRelease = async () => {
		try {
			const { data } = await getLatestChangelogVersion();
			if (data.success) {
				setReleaseDetails(data.data);
			}
		} finally {
		}
	};

	return (
		<div className="max-w-lg mx-auto p-4">
			<Form form={form} layout="vertical" onFinish={onFinish} className="space-y-3">
				<Form.Item name="demoLink">
					<Input placeholder="Demo Link" />
				</Form.Item>

				<Form.Item name="version" rules={[{ required: true, message: "Please enter the version" }]}>
					<Select
						className="min-w-36"
						placeholder="Select Version"
						options={[
							{
								key: "Patch",
								value: "Patch",
								label: "Patch"
							},
							{
								key: "Minor",
								value: "Minor",
								label: "Minor"
							},
							{
								key: "Major",
								value: "Major",
								label: "Major"
							}
						]}
					/>
				</Form.Item>

				<Form.Item>
					<div className="space-x-3">
						<Button type="primary" htmlType="submit">
							Create Release
						</Button>
						<Button type="default" onClick={onUseLatestRelease}>
							Use Latest Release
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};

type TFormValues = {
	title: string;
	releaseId: string;
	changes: string[];
	note?: string;
};

const AddItemToReleaseForm: React.FC = () => {
	const [form] = Form.useForm<TFormValues>();
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [loading, setLoading] = useState(false);
	const [releaseDetails, setReleaseDetails] = useState<null | { _id: string; version: string }>(null);

	const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
		setFileList(fileList);
	};

	const handleSubmit = async ({ releaseId, changes, title, note }: TFormValues) => {
		try {
			setLoading(true);
			const res = await addItemToRelease({
				releaseId,
				title,
				changes,
				note,
				files: fileList.map((_) => _.originFileObj as File)
			});
			if (res.data.success) {
				form.resetFields();
				toast.success(`Changelog added to the release ${releaseDetails?.version ?? ""}`);
			}
		} finally {
			setLoading(false);
		}
	};

	if (!releaseDetails) return <ReleaseForm setReleaseDetails={setReleaseDetails} />;

	return (
		<div className="max-w-lg mx-auto p-4">
			<Form<TFormValues>
				form={form}
				layout="vertical"
				onFinish={handleSubmit}
				className="space-y-4"
				initialValues={{ changes: [""], releaseId: releaseDetails._id }}>
				<Form.Item
					label="Release ID"
					name="releaseId"
					rules={[{ required: true, message: "Please enter the release ID" }]}>
					<Input readOnly />
				</Form.Item>

				<Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter the title" }]}>
					<Input />
				</Form.Item>

				<Form.Item label="Changes" initialValue={{ changes: [""] }}>
					<Form.List name="changes">
						{(fields, { add, remove }) => (
							<div className="grid gap-3">
								{fields.map(({ key, name, ...restField }) => (
									<div key={key} className="grid grid-cols-[1fr,auto] gap-2">
										<Form.Item
											{...restField}
											name={[name]}
											rules={[{ required: true, message: "Please enter a change" }]}
											className="flex-1">
											<TextArea
												placeholder="Enter a change"
												autoSize={{ minRows: 2, maxRows: 4 }}
											/>
										</Form.Item>
										<MinusCircleOutlined onClick={() => remove(name)} />
									</div>
								))}
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
										Add Change
									</Button>
								</Form.Item>
							</div>
						)}
					</Form.List>
				</Form.Item>

				<Form.Item label="Note (optional)" name="note">
					<Input.TextArea rows={2} />
				</Form.Item>

				<Form.Item label="File" rules={[{ required: true, message: "Please upload a file" }]}>
					<Upload
						listType="picture-card"
						accept="image/png, image/jpeg, image/jpg"
						fileList={fileList}
						beforeUpload={() => false}
						multiple
						onChange={handleFileChange}>
						Add Images
					</Upload>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" loading={loading} className="w-full">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddItemToReleaseForm;

