import { apiInstance } from "api/instance";
import { isElectron } from "helpers";

export const checkUserExist = async (method: "PHONE" | "EMAIL", phone: string, email?: string) => {
	const response = await apiInstance.get<
		ApiResponse<{
			exists: boolean;
			role: string[];
		}>
	>(`auth/check-user?method=${method}&phone=${phone}&email=${email}`);
	return response.data.data;
};

export const sendLoginOtp = async (payload: {
	method: "PHONE" | "EMAIL";
	phone?: string;
	email?: string;
	consumer: "nucleus";
	retry: boolean;
}) => {
	const response = await apiInstance.post<ApiResponse<{ session_id: string }>>("auth/send-login-otp", payload);
	return response.data;
};

export const verifyOtpAndSignIn = async (payload: { sessionId: string; otp: string }) => {
	const response = await apiInstance.post<ApiResponse<{ token: string }>>("auth/verify-login-otp", payload);
	if (response?.data?.data?.token) {
		localStorage.setItem("nucleus-jwt-token", response.data.data.token);
		if (isElectron()) {
			(window as any).api?.send("set-token", response.data.data.token);
		}
	}
	return response.data;
};

export const validateSession = async () => {
	const token = localStorage.getItem("nucleus-jwt-token");
	const response = await apiInstance.get<ApiResponse<TCurrentUserResponse>>("users/current", {
		headers: {
			authorization: token
		}
	});
	return response.data;
};

export const getReportees = async (currentRole: TUserRoles) => {
	const res = await apiInstance.get<ApiResponse<TReportees>>(`users/reportees`, {
		withCredentials: true,
		params: {
			currentRole
		}
	});
	return res.data;
};

export const getAppConfig = () => apiInstance.get<ApiResponse<TAppConfig>>("app-configs/frontend");

export const putAppConfig = (data: Record<string, any>) =>
	apiInstance.put<ApiResponse<TAppConfig>>("app-configs/frontend", data);
