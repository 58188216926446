import { Modal } from "antd";
import toast from "components/Commons/Toaster";
import { useEffect, useState } from "react";

const NucleusUpdater: React.FC = () => {
	const [version, setVersion] = useState<string>("");

	useEffect(() => {
		(window as any).api.send("app_version");
		(window as any).api.receive("app_version", async (ver: any) => {
			setVersion(ver.version);
		});
		return () => {
			(window as any).api.removeAllListeners("app_version");
		};
	}, []);

	useEffect(() => {
		(window as any).api.receive("update_available", () => {
			toast.info("A new update is available. Downloading now...");
		});
		return () => {
			(window as any).api.removeAllListeners("update_available");
		};
	}, []);

	useEffect(() => {
		(window as any).api.receive("update_downloaded", () => {
			Modal.confirm({
				title: `Update Downloaded`,
				content: "It will be installed on restart. Do you want to restart?",
				onOk: () => {
					(window as any).api.send("restart_app");
				}
			});
		});
		return () => {
			(window as any).api.removeAllListeners("update_downloaded");
		};
	}, []);

	return <div className="text-center text-xs text-gray-400">v{version}</div>;
};

export default NucleusUpdater;

